import { useQueryClient, useMutation } from '@tanstack/react-query';
import toast from 'react-hot-toast';
import ApiConfig from '../../../../config/ApiConfig';
import { QueryKeys } from '../../../../shared/constants/QueryKeys';
import { doPatch } from '../../../../shared/service/NetworkClient';
import { AssessmentPayload } from '../../../../shared/types/Session';
import { useCreateNoteV2 } from './UseCreateNoteV2';

export const useUpdateSession = (onSuccess?: () => void) => {
  const createNote = useCreateNoteV2();

  const queryClient = useQueryClient();

  return useMutation(
    (body: {
      sessionId: string;
      clientId?: string;
      startTime?: string;
      endTime?: string;
      risk?: number;
      caseNotes: string;
      issue?: string;
      assessment?: Omit<AssessmentPayload, 'isRequired'>[];
      meetingConsent?: 'yes' | 'no';
      sessionType: '0' | '1';
      status: '0' | '1' | '2';
    }) => {
      const { sessionId, ...rest } = body;
      return doPatch(ApiConfig.api.sessions.update + sessionId, rest).then(
        (res) => res.data.data,
      );
    },
    {
      onSuccess: (_data, { caseNotes, sessionId }) => {
        queryClient.invalidateQueries({
          queryKey: [QueryKeys.getSessionData, sessionId],
        });
        createNote.mutate({
          note: caseNotes,
          sessionId,
        });
        // eslint-disable-next-line @typescript-eslint/no-unused-expressions
        onSuccess && onSuccess();
      },
      onError: () => {
        toast.error('An error occurred while completing this session.');
      },
    },
  );
};
