export const QueryKeys = {
  getSessionsList: 'sessions-list',
  getUserProviderData: 'user-provider-data',
  getUserFromNotebook: 'get-details',
  getUserFromEmail: 'get-details-email',
  getOrganisationsList: 'orgs-list',
  getRegionsList: 'regions-list',
  getSimilarEmails: 'email-search',
  getProviderConfig: 'provider-config',
  getAssessmentQuestions: 'assessment-questions',
  getSessionData: 'session-detail',
  getSessionComments: 'session-case-notes',
  getClientECP: 'client-ecp',
};
