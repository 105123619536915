import { useNavigate } from 'react-router-dom';
import React, { useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useUpdateSession } from '../../../../utilities/hooks/queryHooks/caseNotes/UseUpdateSession';
import { PathConfig } from '../../../../config/PathConfig';
import FormSection from '../../../components/formSection/FormSection';
import {
  Button,
  Flex,
  Label,
  Select,
  TextArea,
  Typography,
} from '../../../styledComponents';
import { CareNavForm } from '../../../../shared/types/CareNavForm';
import { CareNavCallReasons } from '../../../../shared/constants/CareNav';



const CareNavNote = () => {
  const navigate = useNavigate();
  const [note, setNote] = useState<string>('');

  const {
    control,
    watch,
    formState: { errors },
  } = useFormContext<CareNavForm>();

  const { mutate: updateSession } = useUpdateSession(() => {
    navigate(PathConfig.careNavigator);
  });

  const saveCareNavNotes = (status: any) => {
    const [id, sessionId] = watch(['id', 'sessionId']);
    if (id && sessionId) {
      updateSession({
        status,
        clientId: id,
        sessionId,
        sessionType: '1',
        issue: watch('reason'),
        caseNotes: note,
        startTime: new Date().toISOString(),
        endTime: new Date().toISOString(),
        risk: 4,
        meetingConsent: 'no',
      });
    }
  };
  return (
    <FormSection title="Notes" subtitle="" defaultOpen>
      <Flex direction="column" gap="2">
        <Label htmlFor="reason">
          Call reason
          <Controller
            name="reason"
            defaultValue={watch('reason')}
            control={control}
            render={({ field: { onChange, value, ...rest } }) => (
              <Select
                options={CareNavCallReasons.map((r) => ({
                  label: r,
                  value: r,
                }))}
                selected={value}
                onChange={onChange}
                {...rest}
              />
            )}
          />
          <Typography color="darkRed" size="xs" css={{ mb: '$2' }}>
            {errors.reason?.message?.toString()}
          </Typography>
        </Label>

        <Label htmlFor="Note">
          Note
          <TextArea
            css={{ mt: '$2' }}
            id="care-nav-notes"
            value={note}
            onChange={(event: any) => setNote(event.target.value)}
          />
        </Label>
        {watch('reason') ? (
          <Flex
            direction="column"
            align="center"
            css={{
              width: '100%',
              mt: '$5',
            }}
            gap="3"
          >
            <Button size="lg" onClick={() => saveCareNavNotes('1')}>
              Mark as complete
            </Button>
            <Button
              variant="outlined"
              size="lg"
              onClick={() => saveCareNavNotes('0')}
            >
              Save as draft
            </Button>
          </Flex>
        ) : null}
      </Flex>
    </FormSection>
  );
};

export default CareNavNote;
