import React, { useState } from 'react';
import { PrsentingIssues } from '../../../shared/constants/PresentingIssues';
import { CaseNoteSession } from '../../../shared/types/Session';
import {
  Button,
  Flex,
  Heading,
  TextArea,
  Typography,
} from '../../styledComponents';
import { useUpdateSession } from '../../../utilities/hooks/queryHooks/caseNotes/UseUpdateSession';

const CallLogDetails = ({ sessionData }: { sessionData: CaseNoteSession }) => {
  const [updatedNote, setUpdatedNote] = useState(
    sessionData.caseNotes.at(-1)?.note ?? '',
  );

  const { mutate: updateSession } = useUpdateSession();

  return (
    <>
      <Heading size="sm" css={{ fontWeight: '600', mt: '$4', mb: '$3' }}>
        Call log details
      </Heading>

      <Typography color="gray600" css={{ mb: '$1' }}>
        Presenting issue
      </Typography>
      <Typography css={{ mb: '$2', lineHeight: '$1', whiteSpace: 'pre-line' }}>
        {sessionData.sessionType === 1
          ? sessionData.issue || 'N/A'
          : PrsentingIssues.flatMap((category) => category.options).find(
              (issue) => issue.value === sessionData.issue,
            )?.label || 'N/A'}
      </Typography>
      <Typography color="gray600" css={{ mb: '$1' }}>
        Note
      </Typography>

      {sessionData.sessionType === 1 && sessionData.status === '0' ? (
        <TextArea
          id="care-nav-notes"
          value={updatedNote}
          onChange={(event) => setUpdatedNote(event.target.value)}
        />
      ) : (
        <Typography
          css={{
            my: '$2',
            lineHeight: '$1',
            whiteSpace: 'pre-line',
          }}
        >
          {sessionData.caseNotes.at(-1)?.note ?? 'N/A'}
        </Typography>
      )}
      <Flex justify="end" css={{ mt: '$2' }}>
        <Typography size="xs" color="gray500">
          {sessionData.responderName}
        </Typography>
      </Flex>
      {sessionData.sessionType === 1 && sessionData.status === '0' && (
        <Button
          onClick={() => {
            updateSession({
              status: '1',
              clientId: sessionData.clientId,
              sessionId: sessionData.id,
              sessionType: `${sessionData.sessionType}`,
              issue: sessionData.issue || '',
              caseNotes: updatedNote || '',
              startTime: sessionData.startTime || new Date().toISOString(),
              endTime: sessionData.endTime || new Date().toISOString(),
              risk: sessionData.risk,
              meetingConsent: sessionData.meetingConsent || 'no',
            });
          }}
        >
          Mark as done
        </Button>
      )}
    </>
  );
};

export default CallLogDetails;
