import axios, { AxiosRequestHeaders } from 'axios';
import ApiConfig from '../../config/ApiConfig';
import {
  attachAuthHeader,
  onRequestFulfilled,
  onRequestRejected,
} from '../../utilities/interceptors/RequestInterceptor';
import {
  onResponseFulfilled,
  onResponseRejected,
} from '../../utilities/interceptors/ResponseInterceptors';
import { AppContext } from '../constants/App';

const axiosClient = axios.create({
  baseURL: ApiConfig.base.notebook,
  withCredentials: false,
  timeout: 30000,
  headers: {
    'Content-Type': 'application/json; charset=utf-8',
    Accept: 'application/json',
    Context: AppContext,
  },
});

// Attach interceptors
axiosClient.interceptors.request.use(onRequestFulfilled, onRequestRejected);
// Attach auth header
axiosClient.interceptors.request.use(attachAuthHeader);
axiosClient.interceptors.response.use(onResponseFulfilled, onResponseRejected);

export const doGet = async (
  url: string,
  headers?: AxiosRequestHeaders,
  params?: Record<string, unknown>,
  abortSignal?: AbortSignal,
) => {
  const axiosConfig = {
    headers,
    params,
    abortSignal,
  };
  return axiosClient.get(url, axiosConfig);
};

export const doPost = async (
  url: string,
  body: Record<string, unknown>,
  headers?: AxiosRequestHeaders,
  params?: Record<string, unknown>,
  abortSignal?: AbortSignal,
) => {
  const axiosConfig = {
    headers,
    params,
    abortSignal,
  };
  return axiosClient.post(url, body, axiosConfig);
};

export const doPatch = async (
  url: string,
  body: Record<string, unknown>,
  headers?: AxiosRequestHeaders,
  params?: Record<string, unknown>,
  abortSignal?: AbortSignal,
) => {
  const axiosConfig = {
    headers,
    params,
    abortSignal,
  };
  return axiosClient.patch(url, body, axiosConfig);
};
